/**
 * Location reducers
 * Reducers for activeLocation and previousLocations
 */

import { REHYDRATE } from 'redux-persist'

import { Address } from '@bff/models/og/addresses'
import { DeliveryWindow } from '@bff/models/og/places'

import t from './actionTypes'

export type Dispensary = {
  id: number
  adultUse: boolean
}

type DepotPaymentSettings = {
  depotId: number
  isCashEnabled: boolean
  isCreditEnabled: boolean
  isCashGrandfathered: boolean
  isCreditPresentEnabled: boolean
  isDebitPinPresentEnabled: boolean
  isDebitCashBackEnabled: boolean
  isDebitOnlineEnabled: boolean
  isACHEnabled: boolean
  isDeliveryFeeEnabled: boolean
  deliveryFeeAmount: number
  minimumAmountBypassDeliveryFee: number
}

type OperatingHours = {
  start: {
    time: string
    offset: number
  }
  end: {
    time: string
    offset: number
  }
}

// should match shape of ContructDepot in redux/action-wrappers/location
export type Depot = {
  dispensary: Dispensary
  deliveryType: string // 'Batch' | 'Hybrid' | 'Dynamic' | 'ThirdParty' - could also see 'Carrier' from when we had Eaze Wellness
  depotPaymentSettings: DepotPaymentSettings
  id: number
  minimumOrderAmount: number
  isDayAndNight: boolean
  payment: string[] // 'Cash' | 'ACH' | 'CreditCard' | 'DebitOnline'
  primaryMenu: string // 'default' | 'shipping'
  schedule: OperatingHours[]
  // Scheduled Delivery
  scheduledDeliveryEnabled: boolean
  deliveryWindows: DeliveryWindow[]
  timeZone: string
}

type Coordinates = {
  latitude: number
  longitude: number
}

export type ActiveLocation = {
  adultUse: boolean
  california: boolean
  city: string
  coordinates: Coordinates
  depot: Depot
  availableDepots: Depot[]
  exact: boolean
  id: string
  menus: string[]
  ordered: boolean
  state: string
  street: string
  supported: boolean
  title: string
  types: any[]
  zip: string
  body: string
  icon: string
}

export const defaultState = {
  address: <Address>{},
  residentialOnly: false,
  exact: false,
  activeLocation: <ActiveLocation>{},
  previousLocations: <ActiveLocation[]>[],
  potentialAddress: <ActiveLocation>{},
  adultUseCityAndDepot: false,
  idScanRequired: false,
  isUsaIdOnly: false
}

const locationReducer = (state = defaultState, { type, payload }: { type: string; payload: any }) => {
  switch (type) {
    case REHYDRATE:
      if (!payload || !payload.location) {
        return defaultState
      }
      return {
        ...state,
        activeLocation: payload.location.activeLocation || {},
        previousLocations: payload.location.previousLocations || []
      }

    case t.SET_PLACE:
      return {
        ...state,
        activeLocation: payload
      }

    case t.REMOVE_PLACE:
      return {
        ...state,
        activeLocation: {}
      }

    case t.PUSH_RECENT_ADDRESS:
      return {
        ...state,
        previousLocations: [payload, ...state.previousLocations]
      }

    case t.REMOVE_PREVIOUS_ADDRESS:
      return {
        ...state,
        previousLocations: [...state.previousLocations.slice(0, -1)]
      }

    // Used when address picker selects different dispensary region
    case t.SAVE_POTENTIAL_ADDRESS:
      return {
        ...state,
        potentialAddress: payload
      }

    case t.CLEAR_POTENTIAL_ADDRESS:
      return {
        ...state,
        potentialAddress: payload
      }

    case t.SET_ADULT_USE_CITY_DEPOT:
      return {
        ...state,
        adultUseCityAndDepot: payload
      }

    case t.IS_ADDRESS_EXACT:
      return {
        ...state,
        exact: payload
      }

    case t.SET_ACTIVE_DEPOT:
      return {
        ...state,
        activeLocation: {
          ...state.activeLocation,
          depot: payload
        }
      }

    case t.SET_ADDRESS:
      return {
        ...state,
        address: payload
      }

    case t.SET_ID_SCAN_REQUIRED:
      return {
        ...state,
        idScanRequired: payload
      }

    case t.SET_RESIDENTIAL_ONLY:
      return {
        ...state,
        residentialOnly: payload
      }

    case t.SET_US_ID_ONLY:
      return {
        ...state,
        isUsaIdOnly: payload
      }

    default:
      return state
  }
}

export default locationReducer
