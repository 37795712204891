/**
 * Action types for user profile
 */
export default {
  NEEDS_PW_RESET: 'NEEDS_PW_RESET',
  PW_RESET: 'PW_RESET',
  RECEIVE_USER_PROFILE: 'RECEIVE_USER_PROFILE',
  RECEIVE_USER_STATUS: 'RECEIVE_USER_STATUS',
  REQUEST_USER_PROFILE: 'REQUEST_USER_PROFILE',
  REQUEST_USER_STATUS: 'REQUEST_USER_STATUS',
  RESET_PROFILE: 'RESET_PROFILE',
  RESET_PASSWORD_ERRORS: 'RESET_PASSWORD_ERRORS',
  SAVE_PHONE_VERIFICATION: 'SAVE_PHONE_VERIFICATION',
  SET_PERSONA_VERIFICATION_STATUS: 'SET_PERSONA_VERIFICATION_STATUS',
  SET_CHANGE_PASSWORD: 'SET_CHANGE_PASSWORD',
  SET_CHANGE_PASSWORD_ERROR: 'SET_CHANGE_PASSWORD_ERROR',
  SET_COLLECTIVE_AGREEMENT: 'SET_COLLECTIVE_AGREEMENT_AS_SAVED',
  SET_DECLINE_REASONS: 'SET_DECLINE_REASONS',
  SET_PROFILE_BASIC: 'SET_PROFILE_BASIC',
  SET_PROFILE_EXTRA: 'SET_PROFILE_EXTRA',
  SET_ID_ORIGIN: 'SET_ID_ORIGIN',
  SET_IDENTIFICATION_PHOTO: 'SET_IDENTIFICATION_PHOTO',
  SET_IDENTIFICATION_CARDTYPE: 'SET_IDENTIFICATION_CARDTYPE',
  SET_NEW_PASSWORD: 'SET_NEW_PASSWORD',
  SET_OLD_PASSWORD: 'SET_OLD_PASSWORD',
  SET_PROFILE_ERROR: 'SET_PROFILE_ERROR',
  SET_RECOMMENDATION_PHOTO: 'SET_RECOMMENDATION_PHOTO',
  SET_VERIFY_PASSWORD: 'SET_VERIFY_PASSWORD',
  UPDATE_BASIC_PROFILE: 'UPDATE_BASIC_PROFILE',
  UPDATE_BASIC_VERIFICATION: 'UPDATE_BASIC_VERIFICATION',
  UPDATE_PATIENT_VERIFICATION: 'UPDATE_PATIENT_VERIFICATION',
  UPDATE_USER_VERIFICATION: 'UPDATE_USER_VERIFICATION'
}
