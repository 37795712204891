/**
 * Checkout reducer
 * It contains all the checkout related reducers
 */
import t from './actionTypes'

export type DeliveryNotes = {
  selectedOption?: {
    text?: string
    isOther?: boolean
    key?: string
  }
  otherMessage?: string
  aptNumber?: string
}

export const defaultState = {
  activeLocationId: '',
  address: {},
  autoSelectedPaymentMethod: <string>null,
  depotMismatch: false,
  error: <string>null,
  isACHDeclineDrawerOpen: false,
  isACHFixDrawerOpen: false,
  message: <string>null,
  messageStatusCode: <number>null,
  deliveryNotes: <DeliveryNotes>{
    selectedOption: null,
    otherMessage: '',
    aptNumber: ''
  },
  notes: '',
  paymentMethod: null,
  potentialAddress: {},
  // scheduled delivery
  isAsapDelivery: true,
  deliveryWindowId: <number>null
}

const checkout = (state = defaultState, action) => {
  switch (action.type) {
    case t.RESET_CHECKOUT:
      return defaultState

    case t.SET_CHECKOUT_ADDRESS:
      return {
        ...state,
        address: action.address
      }

    case t.SET_CHECKOUT_NOTES:
      return {
        ...state,
        notes: action.notes
      }

    case t.SET_DELIVERY_NOTES:
      return {
        ...state,
        deliveryNotes: action.deliveryNotes
      }

    case t.SET_CHECKOUT_ERROR:
      return {
        ...state,
        error: action.payload
      }

    case t.SET_CHECKOUT_MESSAGE: {
      const { message, messageStatusCode } = action.payload
      return {
        ...state,
        message,
        messageStatusCode
      }
    }

    case t.CLEAR_CHECKOUT_MESSAGE:
      return {
        ...state,
        message: null,
        messageStatusCode: null
      }

    case t.CLEAR_CHECKOUT_ERROR:
      return {
        ...state,
        error: null
      }

    // Used only to fire analytics event for first vieing checkout
    case t.VIEW_CHECKOUT:
      return state

    // Used to determine if the user has changed depots from checkout address picker
    case t.SET_DEPOT_MISMATCH:
      return {
        ...state,
        depotMismatch: action.value
      }

    case t.TOGGLE_CARD_DECLINE_DRAWER:
      return {
        ...state,
        isACHDeclineDrawerOpen: !state.isACHDeclineDrawerOpen
      }
    // Notification to re-link a bank account
    case t.TOGGLE_ACH_FIX_DRAWER:
      return {
        ...state,
        isACHFixDrawerOpen: !state.isACHFixDrawerOpen
      }

    case t.SET_ASAP_DELIVERY:
      return {
        ...state,
        isAsapDelivery: true,
        deliveryWindowId: null
      }

    case t.SET_DELIVERY_WINDOW:
      return {
        ...state,
        isAsapDelivery: false,
        deliveryWindowId: action.deliveryWindowId
      }
    case t.SET_AUTO_SELECTED_PAYMENT_METHOD:
      return {
        ...state,
        autoSelectedPaymentMethod: action.paymentMethod
      }
    default:
      return state
  }
}

export default checkout
